import { ApolloProvider } from '@apollo/client';
import Layout from 'containers/Layout';
import client from 'lib/shopify-storefront/client';
import Router from 'next/router';
import NProgress from 'nprogress';
import StorefrontProvider from 'providers/storefront';
import { useEffect } from 'react';
import TagManager from 'react-gtm-module';
import '../styles/globals.css';
import Script from 'next/script';

NProgress.configure({ showSpinner: false });

Router.onRouteChangeStart = () => {
  NProgress.start();
};

Router.onRouteChangeComplete = () => {
  NProgress.done();
};

Router.onRouteChangeError = () => {
  NProgress.done();
};
//NOTE: GTM Manager will loading via .env
// const tagManagerArgs = {
//   id: 'GTM-W8PZV3J',
// };

// Note: Password Protect is temporary. Will be removed before going live.
const MyApp = (props) => {
  const { Component, pageProps } = props;
  const { global, preview } = pageProps || {};

  // useEffect(() => {
  //   TagManager.initialize(tagManagerArgs);
  // }, []);
  useEffect(() => {
    Router.pathname !== '/pc-models/[uid]' &&
      Router.pathname !== '/products/[...handle]' &&
      document &&
      document.body.classList.remove(
        'customize-page-product',
        'product-page-chat',
        'product-page-notify',
      );
  });

  useEffect(() => {
    setTimeout(() => {
      if (typeof window !== 'undefined') {
        const url = new URL(window.location.href);
        if (url.searchParams.has('srsltid')) {
          url.searchParams.delete('srsltid');
          window.history.replaceState(null, '', url.toString());
        }
      }
    }, 500); // Запускаем через 500мс после загрузки
  }, []);

  return (
    <ApolloProvider client={client}>
      <StorefrontProvider globalProps={global}>
        <Layout global={global} preview={preview}>
          {/* Google Tag Manager */}
          <Script
            async
            src='https://www.googletagmanager.com/gtag/js?id=AW-980243423'
            strategy='beforeInteractive'
          ></Script>
          <Script
            id='google-analytics'
            strategy='beforeInteractive'
            dangerouslySetInnerHTML={{
              __html: `
								window.dataLayer = window.dataLayer || [];
								function gtag(){dataLayer.push(arguments);}
								gtag('js', new Date());
								gtag('config', 'AW-980243423');
							`,
            }}
          />
          <Script
            async
            src='https://analytics.ahrefs.com/analytics.js'
            data-key='4u6RzIqAm/CR3KNsqJijNw'
            strategy='afterInteractive'
          />
          <Component {...pageProps} global={global} />
        </Layout>
      </StorefrontProvider>
    </ApolloProvider>
  );
};

export default MyApp;
