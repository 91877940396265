/* global window */
/* global CustomEvent  */
import React, { useEffect, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import {
  CREATE_CART,
  addToCartItemsVars,
  CART_LINES_ADD,
  CART_LINES_UPDATE,
  checkoutAddLineItemsVars,
  useCheckoutEffect,
} from 'helpers/graphql/checkout';
import { StorefrontContext } from 'providers/storefront';
import { useMutation } from '@apollo/client';
import { getCheckoutId } from 'helpers/cookies';
import { addToCartKlaviyo } from 'helpers/klaviyo';
import { addToCartEvent } from 'lib/events/fb-pixel/add-to-cart';
import { fbEvent } from '@rivercode/facebook-conversion-api-nextjs';
import TiktokPixel from 'tiktok-pixel';
import { v4 as uuidv4 } from 'uuid';

import {
  sendShopifyAnalytics,
  AnalyticsEventName,
  getClientBrowserParameters,
} from '@shopify/hydrogen-react';

const AddToBag = ({
  disabled,
  soldOut,
  qty,
  customizedClass,
  products,
  selectedVariant,
  isOnDescription,
  customDesignUrl,
  gcidx,
}) => {
  const { setIsCartOpen, setCheckout, checkout } =
    useContext(StorefrontContext);
  const cartId = checkout?.id;
  const text = soldOut ? 'SOLD OUT' : 'ADD TO CART';
  const fullClassName =
    customizedClass + (disabled ? ' button--disabled sold-out-button' : '');
  const [values, setValues] = useState([]);

  const [
    lineItemReplaceMutation,
    {
      data: lineItemReplaceData,
      loading: lineItemRemoveLoading,
      error: lineItemRemoveError,
    },
  ] = useMutation(CART_LINES_UPDATE);
  useCheckoutEffect(lineItemReplaceData, 'CART_LINES_UPDATE', setCheckout);

  useEffect(() => {
    let data = [];
    products?.forEach((prod) => {
      const instance2Id = prod?.tags
        .find((tag) => tag.startsWith('instance2'))
        ?.split(':')?.[1];
      const Variant = prod?.variants?.edges[0].node;
      const productTitle = prod?.title;

      if (prod?.handle === 'aftershock-gift-card') {
        const GiftCardVariant =
          prod.variants?.edges[localStorage.getItem('giftcard')];

        if (!GiftCardVariant) return;

        data.push({
          merchandiseId: GiftCardVariant.node.id,
          quantity: Number(qty),
          attributes: [
            {
              key: '_lineitem1',
              value: JSON.stringify({
                variantId: instance2Id,
                title: productTitle,
                product_id: GiftCardVariant.node.id,
              }),
            },
          ],
        });
        return;
      }

      if (prod?.tags.length != 0) {
        const specificationTags = prod?.tags.filter((item) =>
          item.trim().startsWith('specifications:'),
        );
        if (specificationTags?.length != 0) {
          let customAttributes = [
            {
              key: '_lineitem1',
              value: JSON.stringify({
                variantId: instance2Id,
                title: productTitle,
                product_id: selectedVariant.id,
              }),
            },
          ];
          specificationTags?.map((item, tag) => {
            let description =
              typeof item.split(':')[3] !== 'undefined'
                ? item.split(':')[3].replace(/-/g, ' ')
                : '';
            customAttributes.push({
              key: '_lineitem' + (tag + 2),
              value: JSON.stringify({
                title: description,
              }),
            });
          });
          data.push({
            merchandiseId: Variant ? Variant.id : null,
            quantity: Number(qty),
            attributes: customAttributes,
          });
        } else {
          data.push({
            merchandiseId: Variant ? Variant.id : null,
            quantity: Number(qty),
            attributes: [
              {
                key: '_lineitem1',
                value: JSON.stringify({
                  variantId: instance2Id,
                  title: productTitle,
                  product_id: selectedVariant.id,
                }),
              },
            ],
          });
        }
      } else {
        let customAttributes = [
          {
            key: '_lineitem1',
            value: JSON.stringify({
              variantId: instance2Id,
              title: productTitle,
              product_id: selectedVariant.id,
            }),
          },
        ];

        data.push({
          merchandiseId: Variant ? Variant.id : null,
          quantity: Number(qty),
          attributes: customAttributes,
        });
      }
    });

    setValues(data);

    // Перевіряємо, чи всі елементи values мають variantId
    const isValidValues = data.every((item) => item.variantId !== null);
  }, [products, qty, customDesignUrl, gcidx]);

  const [
    checkoutCreateMutation,
    {
      loading: checkoutCreateMutationLoading,
      error: checkoutCreateMutationError,
    },
  ] = useMutation(CREATE_CART, {
    variables: addToCartItemsVars(values),
    update(proxy, { data: { cartCreate } }) {
      setCheckout(cartCreate.cart);
      setIsCartOpen(true);
    },
  });

  const [
    checkoutLineItemsAddMutation,
    {
      loading: checkoutLineItemsAddMutationLoading,
      error: checkoutLineItemsAddMutationError,
    },
  ] = useMutation(CART_LINES_ADD, {
    variables: checkoutAddLineItemsVars({
      cartId,
      values,
    }),
    update(proxy, { data: { cartLinesAdd } }) {
      setCheckout(cartLinesAdd.cart);
      updateLineItemsByPrice(cartLinesAdd.cart);
      setIsCartOpen(true);
    },
  });

  const sendAddToCart = (analyticsPageData, product) => {
    const payload = {
      products: [
        {
          productGid: product[0].id,
          name: product[0].title,
          brand: product[0].vendor,
          price: Number(product[0].variants?.edges[0].node.price),
          variantGid: product[0].variants?.edges[0].node.id,
          variantName: product[0].variants?.edges[0].node.title,
          category: product[0].handle,
          sku: product[0].variants?.edges[0].node.sku,
          quantity: Number(qty),
        },
      ],
      ...analyticsPageData,
      ...getClientBrowserParameters(),
    };

    sendShopifyAnalytics({
      eventName: AnalyticsEventName.ADD_TO_CART,
      payload,
    });
  };

  const analyticsShopData = {
    shopId: 'gid://shopify/Shop/63704072436',
    currency: 'SGD',
    acceptedLanguage: 'en',
    hasUserConsent: true,
  };

  const analytics = {
    ...analyticsShopData,
  };

  const handleClick = () => {
    if (cartId) {
      checkoutLineItemsAddMutation();
    } else {
      checkoutCreateMutation();
    }
    const selectedVariant = products[0].variants?.edges[0].node;
    const productTitle = products[0].title;
    const productId = products[0].id;
    const productTags = products[0].tags;
    const productImage =
      products[0]?.images?.edges[0]?.node?.originalSrc ||
      'https://cdn.shopify.com/s/files/1/0637/0407/2436/files/product.png?v=1714032274';
    const productPrice = products[0].variants?.edges[0].node.price;
    const compareAtPrice = products[0].variants?.edges[0].node.compareAtPrice;
    const productHandle = products[0].handle;
    const productSKU = products[0].variants?.edges[0].node.sku;

    const eventId = uuidv4();

    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'add_to_cart',
      event_id: eventId,
      content_type: 'product',
      quantity: Number(qty),
      description: products[0]?.description,
      content_id: productId.replace('gid://shopify/Product/', ''),
      content_name: productTitle,
      currency: 'SGD',
      value: productPrice,
      price: productPrice,
      content_category: 'computer',
    });

    fbEvent({
      eventName: 'AddToCart',
      eventId: eventId,
      products: [
        {
          sku: productSKU,
          quantity: qty,
        },
      ],
      value: productPrice,
      currency: 'SGD',
      enableStandardPixel: false,
    });

    const options = {
      debug: false,
    };

    TiktokPixel.init(process.env.NEXT_PUBLIC_TIKTOK_PIXEL_ID, options);
    TiktokPixel.track('AddToCart', {
      content_type: 'product',
      quantity: qty,
      content_name: productSKU,
      content_id: productId.replace('gid://shopify/Product/', ''),
      currency: 'SGD',
      value:
        productPrice !== 0
          ? productPrice
          : Number(products[0].variants?.edges[0].node.priceV2.amount),
      event_id: eventId,
    });

    sendAddToCart(analytics, products);

    addToCartKlaviyo(
      productTitle,
      productId,
      productSKU,
      productImage,
      productHandle,
      productPrice,
      productTags,
      checkout,
    );
    if (typeof TriplePixel !== 'undefined')
      TriplePixel('AddToCart', {
        item: selectedVariant.id,
        q: qty,
      });

    window.dispatchEvent(
      new CustomEvent('AddToCart', {
        detail: {
          productTitle: selectedVariant.title,
          productCategory: 'Gaming',
          productId: [selectedVariant.id],
          productType: 'product',
          productPrice: selectedVariant.priceV2.amount,
          productCurrency: 'SGD',
        },
      }),
    );
    addToCartEvent({
      title: productTitle,
      variantId: Buffer.from(selectedVariant.id)
        .toString('base64')
        ?.replace('gid://shopify/ProductVariant/', ''),
      qty: Number(qty),
      price: selectedVariant.priceV2.amount,
    });
  };

  const updateLineItemsByPrice = (checkout) => {
    if (checkout.lines.edges.length > 0) {
      let arrayTemp = [...checkout.lines.edges];
      arrayTemp.sort(
        (a, b) =>
          Number(a.node.merchandise.price.amount) -
          Number(b.node.merchandise.price.amount),
      );
      let tempCheckout = { ...checkout };
      tempCheckout.lines = arrayTemp;
      let LineItemIds = [];
      arrayTemp.map((item) => {
        const customAttributes = item.node.attributes.map((item) => {
          const { __typename, ...rest } = item;
          return rest;
        });
        LineItemIds.push({
          attributes: customAttributes,
          quantity: parseInt(item.node.quantity, 10),
          merchandiseId: item.node.merchandise.id,
          id: item.node.id,
        });
      });
      const variables = {
        checkoutId: checkout.id,
        lines: LineItemIds,
      };
      lineItemReplaceMutation({ variables });
    }
  };

  if (checkoutCreateMutationError || checkoutLineItemsAddMutationError) {
    if (checkoutCreateMutationError) console.log(checkoutCreateMutationError);
    if (checkoutLineItemsAddMutationError)
      console.log(checkoutLineItemsAddMutationError);
  }

  return (
    <div
      className={`${
        customizedClass === 'essential_products_cart'
          ? 'add_cart_container'
          : ''
      }`}
    >
      <button
        className={fullClassName}
        onClick={handleClick}
        type='button'
        disabled={
          !!(
            disabled ||
            checkoutCreateMutationLoading ||
            checkoutLineItemsAddMutationLoading
          )
        }
      >
        {checkoutCreateMutationLoading || checkoutLineItemsAddMutationLoading
          ? 'Adding to cart ...'
          : text}
      </button>
      {customizedClass === 'essential_products_cart' ? (
        <div className='arrow_right'>
          <img
            draggable='false'
            src='https://cdn.shopify.com/s/files/1/0637/0407/2436/files/icon_arrow_5.png?v=1714047897'
            alt='arrow_icon'
          />
        </div>
      ) : null}
      <style jsx>
        {`
          .add_to_cart {
            background: #950810;
            width: 100%;
            height: 48px;
            outline: none;
            border: 0;
            border-radius: 3px;
            color: #e4e4e4;
            font-family: 'Roboto-Medium';
            font-size: ${isOnDescription ? '13px' : '16px'};
            font-weight: bold;
            letter-spacing: 0;
            line-height: 48px;
            padding: 0;
          }
          .car_button button {
            font-style: normal;
            font-weight: 600 !important;
            font-size: 13px !important;
            line-height: 24px !important;
            text-align: center;
            color: #e4e4e4 !important;
          }

          .add_cart_container:hover > button {
            background: #800006;
          }
          .add_cart_container:hover > .arrow_right {
            background: #141414;
          }
          .add_to_cart button:hover {
            background: #800006;
          }
          .add_cart_container {
            display: flex;
          }
          .essential_products_cart {
            background: #950810;
            width: 80%;
            height: 48px;
            outline: none;
            border: 0;
            border-radius: 3px 0 0 3px;
            color: #e4e4e4;
            font-family: 'Roboto-Medium';
            font-size: 16px;
            font-weight: bold;
            letter-spacing: 0;
            line-height: 48px;
            padding: 0;
          }
          .arrow_right {
            width: 48px;
            height: 48px;
            background: #242424;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            border-radius: 0px 3px 3px 0px;
          }
          .arrow_right img {
            width: 25px;
          }
          .sold-out-button {
            background-color: #646464;
            color: #e4e4e4;
            font-family: 'Roboto-Medium';
            font-size: 16px;
            line-height: 24px;
          }
        `}
      </style>
    </div>
  );
};

AddToBag.propTypes = {
  disabled: PropTypes.bool,
  soldOut: PropTypes.bool,
  className: PropTypes.string,
  qty: PropTypes.number,
  selectedVariant: PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    priceV2: PropTypes.shape({
      amount: PropTypes.string.isRequired,
    }),
  }),
};

AddToBag.defaultProps = {
  disabled: false,
  soldOut: false,
  className: 'add_to_bag',
  qty: 1,
  selectedVariant: {},
};

export default AddToBag;
