import { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import { StorefrontContext } from 'providers/storefront';
import {
  checkoutById,
  CHECKOUT_QUERY,
  CART_QUERY,
} from 'helpers/graphql/checkout';
import { removeCheckoutId, setCheckoutId } from 'helpers/cookies';

const CartCounter = ({ checkoutId }) => {
  const [totalQuantity, setTotalQuantity] = useState(0);
  const { setCheckout } = useContext(StorefrontContext);
  const { data: checkoutData } = useQuery(CART_QUERY, {
    variables: checkoutById(checkoutId),
  });

  useEffect(() => {
    if ((checkoutData && !checkoutData?.cart) || !checkoutData) {
      removeCheckoutId();
      setCheckout({});
      setTotalQuantity(0);
    } else {
      setTotalQuantity(
        checkoutData?.cart.lines.edges
          .map((item) => item.node.quantity)
          .reduce((x, y) => x + y, 0),
      );
      setCheckout(checkoutData.cart);
      setCheckoutId(checkoutData.cart.id);
    }
  }, [checkoutData]);
  if (!totalQuantity) return <></>;
  return (
    <>
      <div className='cart_badge'>{totalQuantity}</div>
      <style jsx>
        {`
          .cart_badge {
            font-size: 12px;
            line-height: 8px;
            position: absolute;
            top: -25%;
            right: -25%;
            width: 16px;
            height: 16px;
            background: #950810;
            color: #e4e4e4;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 50%;
          }
        `}
      </style>
    </>
  );
};

CartCounter.propTypes = {
  checkoutId: PropTypes.string.isRequired,
};

export default CartCounter;
