import gql from 'graphql-tag';
import { useEffect } from 'react';

export const CartFragment = gql`
  fragment CartFragment on Cart {
    id
    checkoutUrl
    cost {
      totalAmount {
        amount
        currencyCode
      }
      subtotalAmount {
        amount
        currencyCode
      }
    }
    lines(first: 20) {
      edges {
        node {
          id
          quantity
          merchandise {
            ... on ProductVariant {
              id
              sku
              product {
                handle
                title
                tags
                productType
              }
              image {
                url
              }
              price {
                amount
                currencyCode
              }
              compareAtPrice {
                amount
                currencyCode
              }
            }
          }
          attributes {
            key
            value
          }
        }
      }
    }
  }
`;

export const CheckoutFragment = gql`
  fragment CheckoutFragment on Checkout {
    id
    webUrl
    completedAt
    email
    totalTax {
      amount
      currencyCode
    }
    subtotalPrice {
      amount
      currencyCode
    }
    totalPrice {
      amount
      currencyCode
    }
    lineItems(first: 20) {
      edges {
        node {
          id
          title
          quantity
          variant {
            id
            title
            product {
              handle
              tags
              productType
            }
            image {
              src
            }
            price {
              amount
              currencyCode
            }
            compareAtPrice {
              amount
              currencyCode
            }
          }
          quantity
          customAttributes {
            key
            value
          }
        }
      }
    }
  }
`;

export const CREATE_CART = gql`
  mutation cartCreate($input: CartInput!) {
    cartCreate(input: $input) {
      cart {
        ...CartFragment
      }
      userErrors {
        message
        field
      }
    }
  }
  ${CartFragment}
`;

export const CART_LINES_ADD = gql`
  mutation cartLinesAdd($cartId: ID!, $lines: [CartLineInput!]!) {
    cartLinesAdd(cartId: $cartId, lines: $lines) {
      cart {
        ...CartFragment
      }
      userErrors {
        message
        field
      }
    }
  }
  ${CartFragment}
`;

export const CART_QUERY = gql`
  query cart($cartId: ID!) {
    cart(id: $cartId) {
      ...CartFragment
    }
  }
  ${CartFragment}
`;

export const checkoutById = (checkoutId) => ({
  cartId: checkoutId,
});

export const checkoutLineItemsRemove = gql`
  mutation checkoutLineItemsRemove($checkoutId: ID!, $lineItemIds: [ID!]!) {
    checkoutLineItemsRemove(
      checkoutId: $checkoutId
      lineItemIds: $lineItemIds
    ) {
      userErrors {
        message
        field
      }
      checkout {
        ...CheckoutFragment
      }
    }
  }
  ${CheckoutFragment}
`;

export const checkoutLineItemsUpdate = gql`
  mutation checkoutLineItemsUpdate(
    $checkoutId: ID!
    $lineItems: [CheckoutLineItemUpdateInput!]!
  ) {
    checkoutLineItemsUpdate(checkoutId: $checkoutId, lineItems: $lineItems) {
      userErrors {
        message
        field
      }
      checkout {
        ...CheckoutFragment
      }
    }
  }
  ${CheckoutFragment}
`;

export const CART_LINES_UPDATE = gql`
  mutation cartLinesUpdate($checkoutId: ID!, $lines: [CartLineUpdateInput!]!) {
    cartLinesUpdate(cartId: $checkoutId, lines: $lines) {
      cart {
        ...CartFragment
      }
      userErrors {
        message
        field
      }
    }
  }
  ${CartFragment}
`;

export const CART_LINES_REMOVE = gql`
  mutation cartLinesRemove($checkoutId: ID!, $lineItemIds: [ID!]!) {
    cartLinesRemove(cartId: $checkoutId, lineIds: $lineItemIds) {
      cart {
        ...CartFragment
      }
      userErrors {
        message
        field
      }
    }
  }
  ${CartFragment}
`;

export function useCheckoutEffect(data, key, setDataCallback) {
  useEffect(() => {
    if (data && data[key] && data[key].checkout) {
      setDataCallback(data[key].checkout);
    }
  }, [data]);
}

export const addToCartVars = ({ variantId, quantity, customAttributes }) => {
  return {
    input: {
      lines: [
        {
          merchandiseId: variantId,
          quantity,
          customAttributes,
        },
      ],
    },
  };
};

export const addToCartItemsVars = (values) => {
  if (!Array.isArray(values) || values.length === 0) return;

  return {
    input: {
      lines: values.map(({ merchandiseId, quantity, attributes }) => ({
        merchandiseId,
        quantity,
        attributes,
      })),
    },
  };
};

export const checkoutAddLineItemVars = ({
  variantId,
  quantity,
  checkoutId,
  customAttributes,
}) => ({
  checkoutId,
  lines: [
    {
      merchandiseId: variantId,
      quantity,
      customAttributes,
    },
  ],
});
export const checkoutAddLineItemsVars = ({ cartId, values }) => {
  if (!Array.isArray(values) || values.length === 0) return;

  return {
    cartId,
    lines: values.map(({ attributes, merchandiseId, quantity }) => ({
      attributes,
      quantity,
      merchandiseId,
    })),
  };
};
