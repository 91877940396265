import React, { useState, useEffect, createContext } from 'react';
import Cookies from 'js-cookie';
const Context = createContext(null);

const StorefrontProvider = (props) => {
  const { globalProps, children } = props;
  const [isCartOpen, setIsCartOpen] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [checkout, setCheckout] = useState(null);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [profileTabIndex, setProfileTabIndex] = useState(0);
  const [isAnnouncementBarOpen, setIsAnnouncementBarOpen] = useState(true);
  const [customDesignUrl, setCustomDesignUrl] = useState('');
  const [toggleChat, setToggleChat] = useState(null);
  const [showBothChat, setShowBothChat] = useState(null);
  const [checkLocation, setCheckLocation] = useState(null);
  const [allPcRtsProducts, setAllPcRtsProducts] = useState([]);
  const [isLaptop, setIsLaptop] = useState(false);

  const [openSearch, setOpenSearch] = useState(false);
  const [filteredResults, setFilteredResults] = useState([]);
  const [searchInput, setSearchInput] = useState('');
  const [selectedDiscussion, setSelectedDiscussion] = useState();

  useEffect(() => {
    if (Cookies.get('customerToken')) {
      setIsLoggedIn(true);
    }
  });

  useEffect(() => {
    // if (window && window.$zopim.livechat) {
    //turn off initially
    // window.$zopim.livechat.hideAll()
    //client requested that they want it visible initially
    // }
  }, []);

  return (
    <Context.Provider
      value={{
        globalProps,
        isCartOpen,
        isAnnouncementBarOpen,
        profileTabIndex,
        isMenuOpen,
        isLoggedIn,
        checkout,
        customDesignUrl,
        toggleChat,
        showBothChat,
        checkLocation,
        allPcRtsProducts,
        isLaptop,
        setIsLoggedIn,
        setProfileTabIndex,
        setIsMenuOpen,
        setCheckout,
        setIsAnnouncementBarOpen,
        setIsCartOpen,
        setCustomDesignUrl,
        setToggleChat,
        setShowBothChat,
        setCheckLocation,
        setAllPcRtsProducts,
        setIsLaptop,

        openSearch,
        filteredResults,
        searchInput,
        selectedDiscussion,
        setSelectedDiscussion,
        setOpenSearch,
        setFilteredResults,
        setSearchInput,
      }}
    >
      {children}
    </Context.Provider>
  );
};

export default StorefrontProvider;
export { Context as StorefrontContext };
